import { useState, useRef, useMemo } from 'react'
import styled, { css } from 'styled-components'
import theme from 'stories/utils/theme'
import { useTranslation } from 'next-i18next'
import { ButtonText, Headline2, Headline4, Subtitle1 } from 'stories/elements/Typography/Text'
import { colorToCssFilter } from 'lib/features/color'
import ComplexKnowCard from 'components/modules/Knows/ComplexKnowCard'
import { useRouter } from 'next/router'
import { HomepageSubject, returnSubjects } from 'lib/constants/homepage'
import { parseLocale } from 'lib/constants/localization'
import { NewButtonLink } from 'components/elements/NewButton'
import { seoSubjectsRoute } from 'lib/constants/routes'
import { getCountryLanguageConfig } from 'lib/constants/countryLanguageConfig'

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: ${theme.colors.snowWhite};
    display: grid;
    overflow: hidden;
    place-content: start center;
    padding-top: 50px;
    h2 {
        padding: 0 15%;
    }

    .button-wrapper {
        display: grid;
        place-content: center;
    }
    @media (max-height: 800px) {
        min-height: 800px;
    }
    @media (max-width: 1440px) {
        .button-wrapper {
            place-content: start center;
            height: 300px;
        }
    }
    @media (max-width: 1050px) {
        h2 {
            padding: 0 5%;
        }
        height: auto;
    }
    @media (max-width: 750px) {
        height: 800px;
        min-height: auto;
        padding-top: 5px;
        grid-template-columns: 1fr;
        .button-wrapper {
            height: auto;
        }
        h2 {
            padding: 10px 25px 10px 50px;
            text-align: left;
        }
    }
`

const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 350px 1fr;
    overflow-x: hidden;
    position: relative;

    @media (max-width: 1440px) {
        grid-template-columns: 1fr;
        padding-left: 25px;
        grid-template-rows: 100px 1fr;
    }
    @media (max-width: 750px) {
        grid-template-columns: 1fr;
        padding-left: 0;
        grid-gap: 25px;
        grid-template-rows: 75px 1fr;
    }
`

interface SubjectButtonProps {
    isSelected?: boolean
    hoverColor: string
}
const SubjectButton = styled.div<SubjectButtonProps>`
    display: flex;
    width: 245px;
    height: 50px;
    grid-gap: 5px;
    padding: 5px 0 5px 15px;
    align-items: center;
    cursor: pointer;
    ${({ isSelected, hoverColor }) =>
        isSelected
            ? css`
                  border: 3px solid ${hoverColor};
                  border-radius: ${theme.borderRadius.normal};
                  background-color: ${theme.colors.white};
                  h4,
                  h5 {
                      color: ${hoverColor};
                  }
                  @media (max-width: 750px) {
                      border: 2px solid ${hoverColor};
                  }
              `
            : ''};
    svg {
        ${({ isSelected, hoverColor }) =>
            isSelected
                ? css`
                      filter: ${colorToCssFilter(hoverColor.toLowerCase())};
                  `
                : ''};
    }
    @media (max-width: 1440px) {
        padding: 5px 15px 5px 15px;
        margin-bottom: 5px;
        height: 40px;
        width: fit-content;
        min-width: auto;
        h5 {
            white-space: nowrap;
        }
    }
`
const SubjectsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    @media (max-width: 1440px) {
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 0px;
        padding: 0 25px;
        h4 {
            font-size: 25px;
            font-weight: 700;
            line-height: 36px;
        }
    }
    @media (max-width: 750px) {
        justify-content: start;
        overflow-x: hidden;
        flex-wrap: nowrap;
    }
`

const ExampleKnows = styled.div`
    overflow: visible;
    width: 100%;
    display: flex;

    align-items: center;
    justify-content: center;
    padding: 50px 0;
    gap: 15px;
    @media (max-width: 1440px) {
        padding-top: 75px;
        align-items: start;
    }
    @media (max-width: 1050px) {
        padding-top: 50px;
        flex-wrap: wrap;
        height: auto;
    }
    @media (max-width: 750px) {
        min-height: auto;
        width: 100vw;
        align-items: start;
        justify-content: start;
        flex-wrap: nowrap;
        grid-gap: 25px;
        overflow: scroll;
        padding: 0 10%;
        scroll-padding-left: 25px;
        scroll-snap-points-x: repeat(325px);
        scroll-snap-type: x mandatory;
        .scroll {
            scroll-snap-align: start;
        }
    }
`

const Spacer = styled.div`
    @media (max-width: 750px) {
        display: none;
    }
`

interface Props {
    isMobile?: boolean
}

const LearningMaterialsComponent = ({ isMobile }: Props) => {
    const { t } = useTranslation('homepage')
    const subjectSection = useRef<HTMLDivElement>(null)
    const { locale } = useRouter()
    const parsedLocale = parseLocale(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const subjects = useMemo(() => returnSubjects(parsedLocale.contentLanguageCode, t), [parsedLocale.contentLanguageCode])
    const [selectedSubject, setSelectedSubject] = useState<HomepageSubject>(subjects[0])
    const countryLanguageConfig = getCountryLanguageConfig(locale)
    const isLaunched = countryLanguageConfig?.isLaunched

    const handleButtonClick = (subject: HomepageSubject, index: number) => {
        isMobile && subjectSection && subjectSection.current!.scrollTo({ left: 120 * index })
        setSelectedSubject(subject)
    }

    if (!subjects?.length) return null

    return (
        <Container>
            <Headline2 color={theme.colors.accentBlue} textAlign="center">
                {t('homepage/learningMaterialsHeader')}
            </Headline2>
            <ContentContainer>
                <SubjectsSection ref={subjectSection}>
                    {subjects.map((data, i) => (
                        <SubjectButton
                            onClick={() => handleButtonClick(data, i)}
                            key={`subject-${data.title}`}
                            isSelected={data.title === selectedSubject?.title}
                            hoverColor={selectedSubject?.color}
                        >
                            {data.icon}
                            {isMobile ? (
                                <Subtitle1 color={theme.colors.accentBlue}>{data.title}</Subtitle1>
                            ) : (
                                <Headline4 color={theme.colors.accentBlue}>{data.title}</Headline4>
                            )}
                        </SubjectButton>
                    ))}
                </SubjectsSection>
                <ExampleKnows>
                    {selectedSubject?.knowUuids?.length &&
                        selectedSubject.knowUuids.map((uuid, i) => (
                            <ComplexKnowCard
                                key={`know-${uuid}-${i}`}
                                knowUuid={uuid}
                                highlight
                                mobileHighlight
                                hoverColor={selectedSubject.color}
                                startSnapScroll
                                disabledLinking={!isLaunched}
                            />
                        ))}
                </ExampleKnows>
                <Spacer />
                <div className="button-wrapper">
                    <NewButtonLink
                        color={theme.colors.accentBlue}
                        borderRadius={theme.borderRadius.normal}
                        width={325}
                        height={40}
                        href={seoSubjectsRoute}
                    >
                        <ButtonText color={theme.colors.white}>{t('homepage/moreSubjectButtonText')}</ButtonText>
                    </NewButtonLink>
                </div>
            </ContentContainer>
        </Container>
    )
}

export default LearningMaterialsComponent
