import React from 'react'
import styled from 'styled-components'
import theme from 'stories/utils/theme'
import { ButtonText, Headline1 } from 'stories/elements/Typography/Text'
import { useTranslation } from 'next-i18next'
import InterchangingTextComponent from 'components/modules/Homepage/InterchangingTextComponent'
import { NewButtonLink } from 'components/elements/NewButton'
import VideoPlayer from 'components/modules/Players/VideoPlayer'
import { getHeroVideo } from 'lib/constants/homepage'
import { useRouter } from 'next/router'
import { downloadRoute, knowCreateOptionsRoute } from 'lib/constants/routes'
import { parseLocale } from 'lib/constants/localization'
import { getCountryLanguageConfig } from 'lib/constants/countryLanguageConfig'

const HeaderContainer = styled.div`
    width: 100%;
    height: 800px;
    background-color: ${theme.colors.deepSeaBlue};
    display: grid;
    overflow: hidden;
`

const MainContentContainer = styled.div`
    display: flex;
    overflow: visible;
    flex-direction: column;
    justify-content: start;
    padding-top: 75px;
    align-items: center;
    position: relative;
    gap: 15px;

    h1 {
        width: 65%;
        position: relative;

        @media (max-width: 375px) {
            width: 80%;
        }
    }
    .video-wrapper {
        margin-bottom: -30px;
    }
`
const FloatingButtonContainer = styled.div`
    position: absolute;
    bottom: 12.5vh;
    z-index: ${theme.zIndex.FAB};
`

const HeaderMobileComponent = () => {
    const { t } = useTranslation('homepage')
    const { locale } = useRouter()
    const parsedLocale = parseLocale(locale)
    const countryLanguageConfig = getCountryLanguageConfig(locale)
    const isLaunched = countryLanguageConfig?.isLaunched

    return (
        <HeaderContainer>
            <MainContentContainer>
                <Headline1 textAlign="center" color={theme.colors.white} marginBottom={30} isREM>
                    {t('homepage/pageHeader')}
                    <br />
                    <InterchangingTextComponent t={t} />
                </Headline1>
                <VideoPlayer
                    width={244}
                    height={444}
                    autoPlay
                    loop
                    muted
                    objectCover
                    sourceType="video/mp4"
                    showOverlay
                    border={`5px solid ${theme.colors.accentBlue}`}
                    source={getHeroVideo(parsedLocale.interfaceLanguageCode) ?? ''}
                />
                <FloatingButtonContainer>
                    {!isLaunched ? (
                        <NewButtonLink
                            height={50}
                            width={325}
                            borderRadius={theme.borderRadius.normal}
                            type="white"
                            fullWidth={false}
                            href={knowCreateOptionsRoute({ referrerScreen: 'homepage' })}
                        >
                            <ButtonText color={theme.colors.knowunityBlue}>{t('homepage/expansionUploadCTA')}</ButtonText>
                        </NewButtonLink>
                    ) : (
                        <NewButtonLink
                            height={50}
                            width={325}
                            borderRadius={theme.borderRadius.normal}
                            type="white"
                            fullWidth={false}
                            href={downloadRoute}
                        >
                            <ButtonText color={theme.colors.knowunityBlue}>{t('homepage/conversionCTAButton')}</ButtonText>
                        </NewButtonLink>
                    )}
                </FloatingButtonContainer>
            </MainContentContainer>
        </HeaderContainer>
    )
}

export default HeaderMobileComponent
