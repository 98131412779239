export enum KnowunityNumbers {
    downloadMillionCount = 13,
    knowThousandsCount = 950,
    newUsersWeekly = 150000,
    activeCountryCount = 12,
    reviewsThousandsCount = 100,
    appleReviewRating = 4.9,
    googleReviewRating = 4.8,
    appleReviewsCount = '50 000+ Reviews',
    googleReviewsCount = '25 000+ Reviews',
}
