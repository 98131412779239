import React, { useRef } from 'react'
import styled from 'styled-components'
import theme from 'stories/utils/theme'
import { useTranslation } from 'next-i18next'
import { ButtonText, Headline2 } from 'stories/elements/Typography/Text'
import { proPlanOptionRoute } from 'lib/constants/routes'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import NextImage from 'components/elements/NextImage'
import Stack from 'components/elements/Stack'
import DynamicButton from 'components/modules/LandingPage/DynamicButton'
import HeroImage from 'assets/pro-plan/hero.webp'
import {
    AnalyticsEventProPlanEntryFunnelHomepageClick,
    AnalyticsEventProPlanEntryFunnelHomepageImpression,
} from 'lib/constants/AnalyticsEventType'
import useImpressionTracking from 'lib/hooks/useImpressionTracking'
import { trackEvent } from 'lib/tracking/analytics-service'

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${theme.colors.snowWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
        height: fit-content;
    }
`

const Card = styled.div`
    height: 80vh;
    width: 90%;
    @media (max-width: 1250px) {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
    border-radius: ${theme.borderRadius.huge};
    background: rgb(82, 240, 141);
    background: linear-gradient(140deg, rgba(82, 240, 141, 1) 0%, rgb(41, 98, 255) 100%);
    background-blend-mode: lighten;
    overflow: hidden;
    position: relative;
    display: grid;
    place-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding: 0 10%;
    .sm-button {
        display: none;
    }
    .back {
        position: relative;
        right: -150px;
        bottom: -50px;
        @media (max-width: 750px) {
            img {
                width: 100%;
                height: auto;
            }
            right: unset;
        }
    }

    @media (min-width: 1680px) {
        h2 {
            width: 80%;
        }
    }
    @media (max-width: 1440px) {
        padding: 0 5%;
    }
    @media (max-width: 1250px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2,
        .md-button {
            width: 100vw;
            padding: 0 10%;
            left: 0;
            text-align: center;
            display: flex;
            justify-content: center;
        }

        .md-button {
            width: 100%;
        }
    }

    @media (max-width: 750px) {
        height: 800px;
        grid-template-columns: 1fr;
        h2 {
            width: 100%;
            left: 15px;
            top: 15px;
            text-align: left;
        }
    }
`

const AssetWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    position: relative;

    @media (max-width: 1250px) {
        align-items: center;
        place-content: center;
    }
`

interface Props {
    isMobile?: boolean
}

const ProFunnelEntryPoints = ({ isMobile }: Props) => {
    const { t } = useTranslation('homepage')
    const md = useMedia(deviceSize.md)

    const bannerRef = useRef<HTMLDivElement>(null)

    useImpressionTracking(bannerRef, AnalyticsEventProPlanEntryFunnelHomepageImpression)

    const proFunnelHomepageRedirect = () => {
        trackEvent(AnalyticsEventProPlanEntryFunnelHomepageClick, {})
    }

    return (
        <Container>
            <Card>
                <div ref={bannerRef}>
                    <Stack gutter={50}>
                        <Headline2 color={theme.colors.white} marginBottom={0}>
                            {t('homepage/proPlanEntryPointTitle')}
                        </Headline2>
                        <div className="md-button">
                            <span onClick={proFunnelHomepageRedirect}>
                                <DynamicButton
                                    target="_blank"
                                    href={proPlanOptionRoute({ referrerScreen: 'proHomeSection' })}
                                    width={md || isMobile ? undefined : 325}
                                    height={50}
                                    color={theme.colors.white}
                                    borderRadius={theme.borderRadius.normal}
                                    fullWidth={md}
                                >
                                    <ButtonText color={theme.colors.accentBlue}>
                                        {t('homepage/proPlanEntryPointCTA')}
                                    </ButtonText>
                                </DynamicButton>
                            </span>
                        </div>
                    </Stack>
                </div>
                <AssetWrapper>
                    <div className="back">
                        <NextImage
                            src={HeroImage.src}
                            alt="become knower frame"
                            height={HeroImage.height}
                            width={HeroImage.width}
                            lazyLoading
                        />
                    </div>
                </AssetWrapper>
            </Card>
        </Container>
    )
}

export default ProFunnelEntryPoints
